$primary: #ebbd7b;/* MAIN COLOR */
$secondary: #000; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


@import url('https://fonts.googleapis.com/css?family=Athiti|Julius+Sans+One');
//font-family: 'Julius Sans One', sans-serif;
//font-family: 'Athiti', sans-serif;
.xdebug-error .xe-warning {
    display: none;
}

h2, h3 {
	font-family: 'Julius Sans One', sans-serif;
}



p {
	font-family: 'Athiti', sans-serif;
}

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .navbar-nav {
        >li>a {
            border-radius: $border-radius;
            text-align: center;
            margin-top: 25px;
            display: flex;
            align-items: center;
            color: $blk;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }

   			@media(max-width: 640px) and (orientation: landscape) {
				margin-top: -5px;
			}

            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                transition-duration: 0.5s;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.logo {
    max-width: 175px;
    padding: 0.2em;

	@media (max-width: 767px){
		 max-width: 150px;
	}

	@media (max-width: 600px){
		 max-width: 125px;
	}

    @media(max-width: 640px) and (orientation: landscape) {
        max-width: 120px;
        padding: 0.5em;
    }

	@media(max-width: 568px) and (orientation: landscape) {
		max-width: 120px;
		padding: 0.5em;
	}
}



@media(max-width: 767px) {
	
    .navbar-header a {
        float: left;
    }
}

.navbar-toggle {
    margin: 26px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

    @media(max-width: 640px) and (orientation: landscape) {
    margin-top: 20px;    
    }

    @media (max-width: 600px){
     margin-top: 20px;		
    }
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
		input {
            color: #000;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 50%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $secondary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}


.top-pad {
    padding: 3em;
    background: white;
}

.btn {
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
    	transition-duration: 0.5s;
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }

}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.intro {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 400px 0px;

	
	@media (max-width: 767px){
		background-position: 50% 50%;
		padding: 150px 0px;	
	}

	h1 { 
		font-family: 'Julius Sans One', sans-serif;
        color: #fff;

       	@media (max-width: 767px){
       		font-size: 25px;
		}    
    }

}

.divider {
    //background-color: #777;
    //background: url(../img/banner.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/banner2.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 30%;
    padding: 200px 0px;

   	@media (max-width: 1400px) {
		background-attachment: unset;
	}

    @media (max-width: 767px){
		background-position: 38% 50%;
		padding: 150px 0px;	
	}
	
    h1 { 
		font-family: 'Julius Sans One', sans-serif;
        color: #fff;

       	@media (max-width: 767px){
       		font-size: 25px;
		}    
    }
}

.form {
	padding-bottom: 25px; 

    .tpad {
        @media (max-width: 767px) {
            margin-top: -30px;
            margin-bottom: 20px;
        }
    }

	h1 {
		font-family: 'Julius Sans One', sans-serif;
        color: #333;
       	font-size: 35px;	

        @media (max-width: 1200px) {
			font-size: 22px;		
		}

        @media (max-width: 767px) {
			font-size: 25px;		
		}
    }

	p {
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 13px;
			
		}
	}
}

.divider2 {
    background-color: #777;
    // background: url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 100px 0px;

    @media (max-width: 767px){
    	 padding: 50px 0px;
    }

    .desc {
    	margin: 0px auto;
    	max-width: 1250px;
    }

    h1{
		font-family: 'Julius Sans One', sans-serif;
        color: #fff;

        @media (max-width: 767px) {
			font-size: 30px;		
		}
    }
    p {
		font-size: 16px;
		color: #fff;
		
		@media (max-width: 767px) {
			font-size: 13px;		
		}
	}
}

.outro {
    //background: url(../img/banner.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/banner3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    padding: 250px 0px;

	@media (max-width: 767px){
		background-position: 50% 50%;
		padding: 150px 0px;	
	}

	h1 { 
		font-family: 'Julius Sans One', sans-serif;
        color: #fff;

       	@media (max-width: 767px){
       		font-size: 25px;
		}    
    }
}


.img1 {
    //background: url(../img/img2.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/img2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 100px 0px;
    margin-top: 0px !important;

    h1 {
        color: #fff;    
    }
}

.img2 {
    //background: url(../img/img2.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/img2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 100px 0px;
    margin-top: 0px !important;

    h1 {
        color: #fff;    
    }
}

@media (max-width:767px){
	.form .col-sm-12{
		padding: 0px;
	}
	.form .col-sm-12 .info{
		padding: 0px 15px;
	}
}